import { DefaultTheme } from "styled-components"

export const theme: DefaultTheme = {
  colors: {
    currentTheme: "Moon",
    black: "#000000",
    white: "#FFFFFF",
    moon: "#edddb3",
    charcoal: "#353239",
    brown: "#54433a",
    hoverText: "#B2AB99",
    moonDark: "#E3CB8A",
    moonComplementary: "#B3C3ED",
    html: "#E44D26",
    css: "#1572B6",
    javascript: "#F0DB4F",
    rubyonrails: "#A62C39",
    react: "#61DAFB",
    chelsea: "#02ac91",
    aws: "#F7A80D",
    typescript: "#007acc",
    python: "",
    postgresql: "",
    java: "#EA2D2E",
    git: "",
    firebase: "",
    lightPurple: "#c9b3ed",
    purple: "#8c7da6",
    limeGreen: "#dcedb3",
    teaGreen: "#9aa67d",
    api: "#273473",
    greyWater: "#888",
  },
}

export const secondTheme = {
  colors: {
    currentTheme: "Violet",
    black: "#000000",
    white: "#FFFFFF",
    moon: "#c9b3ed",
    charcoal: "#353239",
    brown: "#54433a",
    hoverText: "#B2AB99",
    moonDark: "#8c7da6",
    moonComplementary: "#B3C3ED",
    html: "#E44D26",
    css: "#1572B6",
    javascript: "#F0DB4F",
    rubyonrails: "#A62C39",
    react: "#61DAFB",
    chelsea: "#02ac91",
    aws: "#F7A80D",
    typescript: "#007acc",
    python: "",
    postgresql: "",
    java: "#EA2D2E",
    git: "",
    firebase: "",
    lightPurple: "#edddb3",
    purple: "#E3CB8A",
    limeGreen: "#dcedb3",
    teaGreen: "#9aa67d",
    api: "#273473",
    greyWater: "#888",
  },
}

export const thirdTheme = {
  colors: {
    currentTheme: "Dark",
    black: "#888",
    white: "#1C1C1C",
    moon: "#353239",
    charcoal: "#FFFFFF",
    brown: "#54433a",
    hoverText: "#B2AB99",
    moonDark: "#161c20",
    moonComplementary: "#B3C3ED",
    html: "#E44D26",
    css: "#1572B6",
    javascript: "#F0DB4F",
    rubyonrails: "#A62C39",
    react: "#61DAFB",
    chelsea: "#02ac91",
    aws: "#F7A80D",
    typescript: "#007acc",
    python: "",
    postgresql: "",
    java: "#EA2D2E",
    git: "",
    firebase: "",
    lightPurple: "#c9b3ed",
    purple: "#8c7da6",
    limeGreen: "#dcedb3",
    teaGreen: "#edddb3",
    api: "#273473",
    greyWater: "#888",
  },
}
