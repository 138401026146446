import React from "react"
import { Logo } from "./Logo.style"
import "./LogoWhite.css"

export const LogoWhite = () => {
  return (
    <Logo
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 1000 1000"
      id="logo-white"
      fill="transparent"
    >
      <style type="text/css"></style>
      <g id="Layer_4"></g>
      <g id="Layer_6"></g>
      <g id="Layer_3"></g>
      <g id="Layer_1"></g>
      <g id="Layer_16"></g>
      <g id="Layer_18"></g>
      <g id="OJ_x5F_ROH_x5F_v01"></g>
      <g id="OJ_x5F_JONG_x5F_v01"></g>
      <g id="OJ_x5F_SOO_x5F_v01">
        <g>
          <g>
            <g id="third-white">
              <path
                d="M367.9,726.6h-40l-15-40.8c-2.4-7.8-2.6-5.8-4-0.8l-11,41.6h-40.2l0.8-3h8.6l20.8-77.8
					c9-34.6,36.6-61.4,75.4-61.4h2.8c30,0,52.2,23,44.2,53.4c-5.4,20.4-23.2,51.4-65,52.4c2.2,5.8,11.6,27.4,12.6,30.4
					c0.8,2.4,1.8,3,3.4,3h7.4L367.9,726.6z M377.7,632.6c8-30.6,8-45-13-45h-1.6c-17.6,0-32.6,16.2-38,36.4l-8.6,32.4
					c-6.4,24.6,1.2,29.8,21,29.8c24.4,0,34.2-30.8,39-49L377.7,632.6z"
                stroke="#ffffff"
                strokeWidth="2"
              />
              <path
                d="M555,576h-8.6l0.8-3c15.6,0,30.4,0,35.4-1.2l-17,63.6c1.4-1.6,4.2-6,9.2-10c14.4-11.6,40-9.8,48.4,5.6
					c4.2,7.8,3.8,18.2,0.8,29.2l-15.8,59.6c-1,3.8-0.2,5.6,2.4,5.6c3.8,0,8.2-2.6,10.4-5.8h3.4c-3.2,4.6-8.2,9.4-19.6,9.4h-0.4
					c-17.4,0-23.6-7-19-23.8l14.2-53c2.6-9.4,9.6-31-5.4-31c-19.2,0-29.6,18.6-32.8,29.8L541,727.2c-7-1.6-19.4-2-26.6,0L555,576z"
                stroke="#ffffff"
                strokeWidth="2"
              />
              <path
                d="M411.4,673c8-28.6,27-55,62.4-55c36,0,46,28.4,38,58c-7.4,28.6-28.4,52.6-64.4,52.6
					C411.6,728.6,404,701.6,411.4,673z M486,666.4c6.4-23,8.4-45-10-45s-25.6,16.8-32,39.8l-7.6,27.8c-3.8,15-6.6,36.4,11.8,36.4
					c18.6,0,27-19,30.8-34L486,666.4z"
                stroke="#ffffff"
                strokeWidth="2"
              />
            </g>
            <g id="fourth-white">
              <path
                d="M633.2,715.8c0.6-7.2,6.6-13,14-13s12.6,5.8,11.8,13c-0.8,7.4-6.6,13.2-14,13.2S632.4,723.2,633.2,715.8z"
                stroke="#ffffff"
                strokeWidth="2"
              />
            </g>
          </g>
          <g id="second-white">
            <path
              d="M366.3,416.8h-8.6l0.8-3h40.4L376.1,499c-8.6,32-32.4,57-70.8,57c-16.2,0-26.6-4-32.8-8.6
				c-3.6-2.6-6.4-5.4-8.2-8.6l3.2-1.8c7.2,12,25.6,16.6,39.2,14.4c24.6-4.2,30-23.8,40.2-62.4L366.3,416.8z"
              stroke="#ffffff"
              strokeWidth="2"
            />
            <path
              d="M390.5,499.2c8-28.6,27-55,62.4-55c36,0,46,28.4,38,58c-7.4,28.6-28.4,52.6-64.4,52.6
				C390.7,554.8,383.1,527.8,390.5,499.2z M465.1,492.6c6.4-23,8.4-45-10-45s-25.6,16.8-32,39.8l-7.6,27.8
				c-3.8,15-6.6,36.4,11.8,36.4c18.6,0,27-19,30.8-34L465.1,492.6z"
              stroke="#ffffff"
              strokeWidth="2"
            />
            <path
              d="M494.1,553.4l28-103.2h-8.6l0.8-3c15.6,0,30.4,0,35.4-1.2l-4.2,15.6c1.4-2.2,6-8.6,13.4-12.8
				c14.6-8.6,36.8-3.6,43.6,7c5,8,4.4,19.2,1.4,30.6L588.1,546c-1,3.8-0.2,5.6,2.4,5.6c3.8,0,8.2-2.6,10.4-5.8h3.4
				c-3.2,4.6-8.2,9.4-19.6,9.4h-0.4c-17.4,0-23.6-7-19-23.8l14.2-53c2.6-9.4,9.6-30.8-5.4-30.8c-19.2,0-29.6,18.2-32.8,29.6
				l-20.6,76.2c-7.2-1.6-19.2-2-26.4,0H494.1z"
              stroke="#ffffff"
              strokeWidth="2"
            />
            <path
              d="M664.5,517.8c-12,0-18-2.8-21.8-5c-16,0.6-21.2,6.8-22.8,10.4c-1.8,4.6,1.4,7,9.8,7h46.8
				c26.4,0,31.4,21.4,30.4,32c-3.8,35.2-43.4,44.4-67.8,44.4c-26.6,0-43.2-9.6-43.2-24.8c0-10.2,7.2-21,23.2-23.6
				c-9.4-1.6-11.4-8-11.4-19.2c0-12,10.8-27,32.6-28c-25.4-24.6,2.2-66.8,43-66.8c9.4,0,19.6,2,26,10c7.4-9.6,19.4-16.6,27.2-13.6
				c9.4,3.6,6.8,18-3.2,18.6c-3.8,0.2-7-1.8-7-6.6c0-3,1.8-6.4,0-7.2c-3.8-1.6-10.6,4-15.4,11.4
				C724.7,485.4,695.1,517.8,664.5,517.8z M695.5,575.6c4.2-15-6.6-17.2-17-17.2h-43c-24,0-35,8.6-35.6,23
				c-0.6,12.6,12.4,22,39.8,22.2c21,0,49-8.4,55.4-26.8L695.5,575.6z M689.5,476.2c5.4-19,5.6-29-7-29c-12.6,0-16.6,10-21.4,27.2
				l-3.4,12c-5.6,19.8-4.4,28.2,6.8,28.2c11.8,0,17.6-10.6,21.4-25.6L689.5,476.2z"
              stroke="#ffffff"
              strokeWidth="2"
            />
          </g>
          <g id="first-white">
            <path
              d="M396.3,413.8l6-22.4l2.4,0.2c5.8,16.6,24.8,21.8,41.6,21.8c22.2,0,38.8-6.8,41.8-21
				c6.4-32.2-80.2-29.6-66.4-83c7-26,33-37.8,60-38.4c15.2-0.2,27,2.8,36,10.6c1,0.8,1.8,1,2.8,0.4c1.4-0.8,2.2-2.6,3.6-7.2h3
				l-7,23.8l-3-0.2c2.6-22.2-23.6-24.4-34.2-24.4c-18,0-38.2,8.2-38.8,25.2c-0.8,18.4,54,24.6,67.8,48.2c7.6,12.4,3,28.6-3.4,40.2
				c-10.2,17.6-36.2,29.2-61.4,29.2c-15.8,0-28.8-3.2-39.8-12.6c-3.8-3.2-4-3.4-8.4,9.6H396.3z"
              stroke="#ffffff"
              strokeWidth="2"
            />
            <path
              d="M525.9,360.2c8-28.6,27-55,62.4-55c36,0,46,28.4,38,58c-7.4,28.6-28.4,52.6-64.4,52.6
				C526.1,415.8,518.5,388.8,525.9,360.2z M600.5,353.6c6.4-23,8.4-45-10-45s-25.6,16.8-32,39.8l-7.6,27.8
				c-3.8,15-6.6,36.4,11.8,36.4c18.6,0,27-19,30.8-34L600.5,353.6z"
              stroke="#ffffff"
              strokeWidth="2"
            />
            <path
              d="M639.3,360.2c8-28.6,27-55,62.4-55c36,0,46,28.4,38,58c-7.4,28.6-28.4,52.6-64.4,52.6
				C639.5,415.8,631.9,388.8,639.3,360.2z M713.9,353.6c6.4-23,8.4-45-10-45s-25.6,16.8-32,39.8l-7.6,27.8
				c-3.8,15-6.6,36.4,11.8,36.4c18.6,0,27-19,30.8-34L713.9,353.6z"
              stroke="#ffffff"
              strokeWidth="2"
            />
          </g>
        </g>
      </g>
      <g id="SJ_x5F_J"></g>
      <g id="SJ_x5F_S"></g>
      <g id="SJ_x5F_Right"></g>
      <g id="SJ_x5F_Left"></g>
      <g id="Roh"></g>
      <g id="Jong"></g>
      <g id="Soo"></g>
      <g id="Left"></g>
      <g id="Right"></g>
    </Logo>
  )
}
