import { device } from "style/media"
import styled, { css } from "styled-components"
import { Container } from "../../style/Container.style"
import HomeLogo from "assets/SVG/Logo_Charcoal.svg"
import { LogoChar } from "assets/SVG/LogoChar"
import { LogoWhite } from "assets/SVG/LogoWhite"
import { LogoCharKor } from "assets/SVG/LogoCharKor"
import { LogoWhiteKor } from "assets/SVG/LogoWhiteKor"

export const HomeContainer = styled(Container)`
  height: 100vh;
`

export const HomeBannerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* place-items: center; */

  @media ${device.mobile} {
    height: 18vh;
  }
  @media ${device.tablet} {
    height: 17vw;
  }
`

export const HomeBannerText = styled.p`
  width: 100%;
  /* font-family: "Exo", sans-serif; */
  font-family: "Gugi", cursive;
  font-weight: 600;

  background: linear-gradient(
    219deg,
    ${(props) => props.theme.colors.teaGreen} 25%,
    transparent 25%,
    transparent 26%,
    ${(props) => props.theme.colors.limeGreen} 26%,
    ${(props) => props.theme.colors.limeGreen} 39%,
    transparent 39%,
    transparent 40%,
    ${(props) => props.theme.colors.moonComplementary} 40%,
    ${(props) => props.theme.colors.moonComplementary} 57%,
    transparent 57%,
    transparent 58%,
    ${(props) => props.theme.colors.lightPurple} 58%,
    ${(props) => props.theme.colors.lightPurple} 74%,
    transparent 74%,
    transparent 75%,
    ${(props) => props.theme.colors.purple} 75%
  );
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  transition: all 0.3s ease-out;

  @media ${device.mobile} {
    font-size: 16vh;
    :hover {
      font-size: 16vh;
    }
  }
  @media ${device.tablet} {
    font-size: 15vw;
    :hover {
      font-size: 16vw;
    }
  }
`

interface IProps {
  currentLanguage: string
}

export const HomeText = styled.p<IProps>`
  color: ${(props) => props.theme.colors.charcoal};
  font-size: 42px;
  /* font-family: "Aboreto"; */
  font-weight: 600;

  ${(props) => {
    if (props.currentLanguage === "KR") {
      return css`
        font-family: "Gugi", cursive;
      `
    } else {
      return css`
        font-family: "Aboreto";
      `
    }
  }}
`

export const CountryIcon = styled.span`
  margin: 20px;
  font-size: 50px;
  border-bottom: 2px solid transparent;

  :hover {
    cursor: pointer;
    border-bottom: 2px solid black;
    font-size: 60px;
  }
  transition: all 0.3s ease-out;
`

export const IconsContainer = styled.div`
  bottom: 25px;
  position: absolute;
  display: flex;
  flex-direction: row;
`

export const IconContainer = styled.div``

export const Logo = styled(HomeLogo)`
  position: absolute;
  left: 10;
  top: 10;
`

export const LogoDark = styled(LogoChar)``

export const LogoLight = styled(LogoWhite)``

export const LogoDarkKor = styled(LogoCharKor)``

export const LogoLightKor = styled(LogoWhiteKor)``
